import Carousel from "react-bootstrap/Carousel";
import threeTires from "../img/three-tires.jpg";
import cokerCar from "../img/coker-car.jpg";
import cokerBanner from "../img/slide_2.png";

function LocalCarousel() {
  return (
    <Carousel className="landing__carousel">
      <Carousel.Item>
        <img
          src={threeTires}
          className="landing__carousel--img"
          alt="3 of the Coker Classic Star Series tires with rims on a black background"
        />
        {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={cokerBanner}
          className="landing__carousel--img"
          alt="Coker Banner with information about new product launch, best in class-ic"
        />
        {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item className="landing__carousel--item">
        <img
          src={cokerCar}
          className="landing__carousel--img"
          alt="Coker Classic Car parked next to a large puddle with the reflection of the vehicle mirrored"
        />
        {/* <Carousel.Caption>
          <h3>Coker Classic Star Series Car</h3>
          <p>This beast is on fire! Hot, Hot, Hot!</p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
  );
}

export default LocalCarousel;
