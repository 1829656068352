import cokerTire from "../img/coker-tire.png";
import summit from "../img/summit.png";
import dualTires from "../img/buy_tires.png";

const Buy = ({ facebook, google }) => {
  const cokerTireURL =
    "https://www.cokertire.com/tires/brands/coker-classic-tires/star_series.html";
  const facebookCokerTireURL =
    "https://www.cokertire.com/tires/brands/coker-classic-tires/star_series.html?utm_source=facebook&utm_medium=cpc&utm_campaign=ccsslaunch";
  const googleAdsCokerTireURL =
    "https://www.cokertire.com/tires/brands/coker-classic-tires/star_series.html?utm_source=google&utm_medium=cpc&utm_campaign=ccsslaunch";
  const summitURL =
    "https://www.summitracing.com/search/brand/coker-tire/part-type/tires?filter=CokerClassic&autoview=sku";
  const facebookSummitURL =
    "https://www.summitracing.com/search/brand/coker-tire/part-type/tires?filter=CokerClassic&autoview=sku&utm_source=facebook&utm_medium=cpc&utm_campaign=ccsslaunch";
  const googleAdsSummitURL =
    "https://www.summitracing.com/search/brand/coker-tire/part-type/tires?filter=CokerClassic&autoview=sku&utm_source=google&utm_medium=cpc&utm_campaign=ccsslaunch";
  return (
    <section className="buy__section">
      <div className="buy__container">
        <div className="buy__content">
          <a className="anchor" id="buy"></a>
          <h1 className="buy__heading">In Stock And Ready.</h1>
          <h5 className="buy__heading--sub">
            We hit the ground running with the Coker Classic Star Series.
            Several sizes will be in stock, and we’re adding more to the
            warehouse every week until we’re fully stocked with all 28 sizes.
            You will be able to buy direct from Coker Tire or shop at Summit
            Racing.
          </h5>
        </div>
        {/* <div className="buy__store">
            <img src={summit} className="buy__img" id="summit__image" />
            <a
            href={
              facebook
              ? facebookSummitURL
              : google
              ? googleAdsSummitURL
              : summitURL
              }
              className="buy__button"
              id="summit__button"
              target="_blank"
              >
              BUY NOW
              </a>
              </div> */}
        <div className="buy__locations">
          <div className="buy__store">
            <img src={cokerTire} className="buy__img" />
            <a
              href={
                facebook
                  ? facebookCokerTireURL
                  : google
                  ? googleAdsCokerTireURL
                  : cokerTireURL
              }
              target="_blank"
              id="coker__button"
              className="buy__button"
            >
              BUY NOW
            </a>
          </div>
        </div>
      </div>
      <div className="buy__stores">
        <h1 className="buy__stores--title-domestic">Domestic</h1>
        <a
          href="https://www.performanceplustire.com/"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>Performance Plus</h3>
          </div>
        </a>
        <a
          href="https://www.speedwaymotors.com/"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>Speedway Motors</h3>
          </div>
        </a>
        <a
          href="https://motorspot.com/"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>Motorspot</h3>
          </div>
        </a>
        <a
          href="https://motorstate.com/"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>Motorstate Distributing</h3>
          </div>
        </a>
        <a
          href="https://lucasclassictires.com/"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>Lucas Classic Tires</h3>
          </div>
        </a>
        <a
          href="https://www.corvettecentral.com/"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>Corvette Central</h3>
          </div>
        </a>
        <a
          href="https://www.zip-corvette.com/"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>Zip Products</h3>
          </div>
        </a>
        <a
          href="https://www.jegs.com/"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>JEGS</h3>
          </div>
        </a>
        <h1 className="buy__stores--title-domestic">International</h1>
        <a
          href="http://www.antiquetyres.com.au/tyres"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>Antique Tyre Supplies</h3>
          </div>
        </a>
        <a
          href="http://www.oldtimer-reifen.com"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>Munchner Oldtimer Reifen GMBH</h3>
          </div>
        </a>
        <a
          href="http://www.kustomwheel.com"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>Wheels Unlimited</h3>
          </div>
        </a>
        <a
          href="http://www.queenswaytire.com"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>Queensway Tire</h3>
          </div>
        </a>
        <a
          href="https://www.vintagetirescanada.com/"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>Vintage Tire Supply</h3>
          </div>
        </a>
        <a
          href="www.bgworldwheels.co.nz"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>BG Marketing Ltd</h3>
          </div>
        </a>
        <a
          href="https://www.vintagetyres.com/"
          target="_blank"
          className="buy__stores--links"
        >
          <div className="buy__stores--location">
            <h3>Vintage Tyres</h3>
          </div>
        </a>
        <div className="buy__stores--location">
          <h3></h3>
        </div>
      </div>
      <img src={dualTires} className="buy__banner" />
    </section>
  );
};

export default Buy;
