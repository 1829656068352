import Header from "./header";
import Landing from "./Landing";
import Specs from "./Specs";
import OurPromise from "./OurPromise";
import Fitment from "./Fitment";
import Buy from "./Buy";
import Footer from "./Footer";

const Website = ({ facebook = false, google = false }) => {

  return (
    <div className="app">
      <Header />
      <Landing />
      <Specs />
      <Fitment />
      <OurPromise />
      <Buy facebook={facebook} google={google} />
      <Footer />
    </div>
  );
};

export default Website;
