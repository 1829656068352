import { useState, useEffect } from "react";
import mobileImage from "../img/mobile_2.png";
import LocalCarousel from "./LocalCarousel";

const Landing = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="landing" id="home">
      {width > 500 ? (
        <LocalCarousel />
      ) : (
        <img src={mobileImage} className="landing__image" />
      )}
    </section>
  );
};

export default Landing;
