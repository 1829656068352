import logo from "../img/logo.png";
import facebook from "../img/facebook.png";
import instagram from "../img/instagram.png";
import youtube from "../img/youtube.png";

const Footer = () => {
  return (
    <div className="footer">
      <img src={logo} className="footer__logo" style={{ display: "none" }} />
      <div className="footer__social">
        <a href="https://www.facebook.com/cokertireco/" target="_blank"><img src={facebook} className="footer__social--icon" alt="Facebook" /></a>
        <a href="https://www.instagram.com/cokertireco/?hl=en" target="_blank"><img src={instagram} className="footer__social--icon" alt="Instagram" /></a>
        <a href="https://www.youtube.com/c/cokertireco" target="_blank"><img src={youtube} className="footer__social--icon" alt="YouTube" /></a>
      </div>
      <div className="footer__text">
        <p>&copy; 2024 Coker Tire | 1317 Chestnut St | Chattanooga, TN 37402</p>
      </div>
    </div>
  );
};

export default Footer;
