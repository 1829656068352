import promiseImage from "../img/our-promise-img.png";

const OurPromise = () => {
  const teamLink = "https://www.cokertire.com/the-stars-of-customer-service";
  return (
    <section className="promise__container">
      <div className="promise__content">
        <a className="anchor" id="our-promise"></a>
        <h1 className="promise__heading">Our Promise.</h1>
        <p className="promise__heading--sub">
          Our promise is to treat the customer right from day one and continue
          that relationship for many years down the road…and when the customer
          needs tires again, they’ll know who to call. All of our Customer
          Service Stars will gladly give you their name, extension and email
          address, meaning you have a direct line to a tire and wheel expert
          when you need them.
        </p>
        <a
          className="promise__button"
          target="_blank"
          style={{ textDecoration: "none" }}
          href={teamLink}
        >
          MEET THE TEAM
        </a>
      </div>
      <div className="promise__phrase">
        <div className="promise__text">
          <h1 className="promise__text--heading">"You have my word on it."</h1>
          <p className="promise__text--sub-text">
            Our Customer Service Stars are here to help with fitment advice and
            technical support, and they stand behind our products with an
            industry-leading Life of the Tread Warranty.
          </p>
          <div className="promise__text--box">
            <h1 className="promise__text--sub-heading">Brad</h1>
            <p className="promise__text--job-title">Customer Service Star</p>
          </div>
        </div>
        <img src={promiseImage} className="promise__img" />
      </div>
    </section>
  );
};

export default OurPromise;
