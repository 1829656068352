import "./css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Route, Routes } from "react-router-dom";

import Website from "./components/Website";

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Website />} />
        <Route path="/facebook" element={<Website facebook={true} />} />
        <Route path="/google" element={<Website google={true} />} />
      </Routes>
    </div>
  );
}

export default App;
