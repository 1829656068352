import specsTires from "../img/coker-tire-spec.png";

const Specs = () => {
  return (
    <section className="specs__container">
      <div className="specs__content">
        <a className="anchor" id="specs"></a>
        <div className="specs__heading--box">
          <h1 className="specs__heading">Engineered To Be Best-In-Class.</h1>
        </div>
        <h5 className="specs__heading--sub">
          <ul>
            <li>28 Sizes to Fit 13 to 17-inch Wheels</li>
            <li>Direct Replacement for Many Bias Ply Sizes</li>
            <li>Available in Narrow Whitewall and Wide Whitewall</li>
            <li>The Only Whitewall Tire with T Speed Rating</li>
            <li>Reinforced Sidewall for Extra Load (XL) Rating</li>
            <li>480 TreadWear Rating with Grade-A Traction</li>
          </ul>
        </h5>
        <div className="specs__chart">
          <h2 className="specs__chart--title">
            FEATURES AND BENEFITS COMPARISON
          </h2>
          <div className="specs__chart--data">
            <div className="specs__chart--data-odd">
              <h3 className="specs__chart--spec" id="first__box"></h3>
              <h3 className="specs__chart--spec">Sizes</h3>
              <h3 className="specs__chart--spec">Speed Rating</h3>
              <h3 className="specs__chart--spec">Load Rating</h3>
              <h3 className="specs__chart--spec">Traction</h3>
              <h3 className="specs__chart--spec">Temprature</h3>
              <h3 className="specs__chart--spec">Made In</h3>
              <h3 className="specs__chart--spec">Warranty</h3>
            </div>
            <div className="specs__chart--data-even">
              <h3 className="specs__chart--spec">Coker Classic</h3>
              <h3
                className="specs__chart--spec"
                style={{ backgroundColor: "#eff8f9" }}
              >
                9
              </h3>
              <h3 className="specs__chart--spec">P</h3>
              <h3
                className="specs__chart--spec"
                style={{ backgroundColor: "#eff8f9" }}
              >
                Standard
              </h3>
              <h3 className="specs__chart--spec">B</h3>
              <h3
                className="specs__chart--spec"
                style={{ backgroundColor: "#eff8f9" }}
              >
                B
              </h3>
              <h3 className="specs__chart--spec">North America</h3>
              <h3
                className="specs__chart--spec"
                style={{ backgroundColor: "#eff8f9" }}
              >
                Life of the Tread
              </h3>
            </div>
            <div className="specs__chart--data-odd">
              <h3 className="specs__chart--spec">New Star Series</h3>
              <h3 className="specs__chart--spec">28</h3>
              <h3 className="specs__chart--spec">T</h3>
              <h3 className="specs__chart--spec">Extra Load*</h3>
              <h3 className="specs__chart--spec">A</h3>
              <h3 className="specs__chart--spec">A</h3>
              <h3 className="specs__chart--spec">North America</h3>
              <h3 className="specs__chart--spec">Life of the Tread</h3>
            </div>
          </div>
        </div>
        <p className="specs__heading--sub">
          *Extra load available depending on the size
        </p>
      </div>
      <div className="specs__img--box">
        <h2 className="specs__text--1">
          Best-in-class speed rating, load rating, tread life and traction.
        </h2>
        <h2 className="specs__text--2">
          The only tire of its kind that's engineered and built in North
          America.
        </h2>
        <h2 className="specs__text--3">
          State-of-the-art processes and materials create a perfectly balanced
          tire.
        </h2>
        <h2 className="specs__text--4">
          Life on the Tread Warranty is the most comprehensive in the industry.
        </h2>
        <div className="specs__img--rule"></div>
        <img src={specsTires} className="specs__img" />
      </div>
    </section>
  );
};

export default Specs;
