import { useState, useEffect } from "react";
import roundTire from "../img/fitment_tire.png";
import data from "../data/vehicles.json";

const Fitment = () => {
  const [year, setYear] = useState("Year");
  const [make, setMake] = useState("Make");
  const [model, setModel] = useState("Model");
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const years = [...new Set(data.map((item) => item.Year))];

  useEffect(() => {
    if (window.location.href.includes("#fitment")) {
      const anchor = document.getElementById("fitment");
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const showFilteredVehicles = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <table
          style={{
            display: model === "Model" ? "none" : "inline-block",
          }}
        >
          <tbody>
            <tr>
              <th className="fitment__chart--title">Bias</th>
              <th className="fitment__chart--title">Radial</th>
              <th className="fitment__chart--title">SKU 1</th>
              <th className="fitment__chart--title">SKU 2</th>
            </tr>
            {filteredVehicles.map((vehicle, i) => {
              return (
                <tr key={i}>
                  <td className="fitment__chart--data">{vehicle.Bias}</td>
                  <td className="fitment__chart--data">{vehicle.Size}</td>
                  <td className="fitment__chart--data">{vehicle.SKU1}</td>
                  <td className="fitment__chart--data">{vehicle.SKU2}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <section className="fitment">
      <div className="fitment__container">
        <div className="fitment__content">
          <a className="anchor" id="fitment"></a>
          <h1 className="fitment__heading">A Perfect Fit For</h1>
          <h1 className="fitment__heading">Classics & Customs.</h1>
          <div className="fitment__heading--sub-cols">
            <h5 className="fitment__heading--sub">
              We built this tire specifically for use on classic cars, trucks,
              hot rods and customs to directly replace original style bias ply
              tires or radial tires with subpar construction techniques. With 28
              sizes, the Coker Classic Star Series whitewall tire offers fitment
              for many original classic car applications from the '40s, '50s and
              '60s, as well as staggered sizes for hot rods and custom builds.
              Get the right look and the right fit every time with our Tire
              Finder tool, which has thousands of classic makes and models.
            </h5>
          </div>
        </div>
        <div className="fitment__heading--secondary-boxes">
          <h1 className="fitment__heading--secondary" id="tire_finder">
            Find Your Tire
          </h1>
          <div className="fitment__dropdowns">
            <select
              value={year}
              onChange={(e) => {
                setFilteredVehicles([]);
                setMake("Make");
                setModel("Model");
                setYear(e.target.value);
                setMakes([
                  ...new Set(
                    data
                      .filter((year) => year.Year == e.target.value)
                      .map((item) => item.Make)
                  ),
                ]);
              }}
            >
              <option value={"Year"}>Year</option>
              {years.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={make}
              onChange={(e) => {
                setFilteredVehicles([]);
                setModel("Model");
                setMake(e.target.value);
                setModels([
                  ...new Set(
                    data
                      .filter(
                        (make) =>
                          make.Make == e.target.value && make.Year == year
                      )
                      .map((item) => item.Model)
                  ),
                ]);
              }}
            >
              <option value={"Make"}>Make</option>
              {makes.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={model}
              onChange={(e) => {
                setModel(e.target.value);
                setFilteredVehicles(
                  data.filter(
                    (item) =>
                      item.Year == year &&
                      item.Make == make &&
                      item.Model == e.target.value
                  )
                );
              }}
            >
              <option value={"Model"}>Model</option>
              {models.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <p>
            *This is not an exhaustive list of all vehicles, just the most
            popular for fitment. Contact our sales team for details on your
            particular vehicle.
          </p>
        </div>
        <div className="fitment__chart">{showFilteredVehicles()}</div>
        <img src={roundTire} className="fitment__img" />
      </div>
    </section>
  );
};

export default Fitment;
