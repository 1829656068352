import logo from "../img/logo.png";

const header = () => {
  const listItemClicked = () => {
    document.getElementById("navi-toggle").checked = false;
  };

  return (
    <header className="header">
      <a href="#home">
        <img src={logo} className="header__logo" alt="coker-logo" />
      </a>

      <div className="nav__collapsed">
        <input type="checkbox" className="nav__checkbox" id="navi-toggle" />
        <label htmlFor="navi-toggle" className="nav__button">
          <span className="nav__icon">&nbsp;</span>
        </label>
        <div className="nav__background">&nbsp;</div>

        <nav className="nav__nav">
          <ul className="nav__list--collapsed">
            <li className="nav__item--collapsed">
              <a
                className="nav__link--collapsed"
                href="#specs"
                onClick={() => listItemClicked()}
              >
                SPECIFICATIONS
              </a>
            </li>
            <li className="nav__item--collapsed">
              <a
                className="nav__link--collapsed"
                href="#fitment"
                onClick={() => listItemClicked()}
              >
                FITMENT GUIDE
              </a>
            </li>
            <li className="nav__item--collapsed">
              <a
                className="nav__link--collapsed"
                href="#our-promise"
                onClick={() => listItemClicked()}
              >
                OUR PROMISE
              </a>
            </li>
            <li className="nav__item--collapsed" style={{ display: "none" }}>
              <a
                className="nav__link--collapsed"
                href="#media"
                onClick={() => listItemClicked()}
              >
                MEDIA
              </a>
            </li>
            <li className="nav__item--collapsed">
              <a
                className="nav__link--collapsed"
                href="#buy"
                id="header__list-link-buy"
                onClick={() => listItemClicked()}
              >
                BUY NOW
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <nav className="header__nav">
        <ul className="header__list">
          <li className="header__list-items">
            <a className="header__list-link" href="#specs">
              SPECIFICATIONS
            </a>
          </li>
          <li className="header__list-items">
            <a className="header__list-link" href="#fitment">
              FITMENT GUIDE
            </a>
          </li>
          <li className="header__list-items">
            <a className="header__list-link" href="#our-promise">
              OUR PROMISE
            </a>
          </li>
          <li className="header__list-items" style={{ display: "none" }}>
            <a className="header__list-link" href="#media">
              MEDIA
            </a>
          </li>
          <li className="header__list-items">
            <a
              className="header__list-link"
              href="#buy"
              id="header__list-link-buy"
            >
              BUY NOW
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default header;
